<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Template' : 'Cadastrar Template'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="$route.params.id > 0 ? btdelete : null"
    :btback="{}"
    :busy="loading"
    @clicked-save="save"
    @clicked-delete="confirmDelete"
  >
    <hr class="p-0 m-0 mb-1" />
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col cols="12" md="12" v-if="record.id > 0">
              <b-form-group label="Nome">
                <b-form-input
                  v-model="record.name"
                  placeholder="Nome para Template"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" v-else>
              <b-form-group label="Nome">
                <b-form-input
                  v-model="record.name"
                  placeholder="Nome para Template"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Assunto">
                <b-form-input
                  v-model="record.subject"
                  placeholder="Assunto do e-mail"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Tipo">
                <v-select
                  v-if="types.length > 0"
                  v-model="typeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  autocomplete="off"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Notificação do Sistema">
                <b-form-checkbox
                  class="custom-control-success mt-0 mb-1"
                  name="check-button"
                  switch
                  v-model="record.is_system"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Mensagem">
                <quill-editor
                  style="height: 245px; padding-bottom: 55px"
                  v-model="record.content"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col cols="6" md="6">
              <b-form-group label="Endpoint">
                <b-form-input
                  v-model="record.endpoint"
                  placeholder="Endpoint do controller"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6">
              <b-form-group label="Método">
                <b-form-input
                  v-model="record.method"
                  placeholder="Método de Envio (POST, PUT, DELETE)"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Enviado via">
                <v-select
                  v-if="destinies"
                  v-model="destiniesSelected"
                  :options="destinies"
                  autocomplete="off"
                  multiple
                  :clearable="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Enviar para permissões">
                <v-select
                  v-model="permissionsSelected"
                  :options="permissions"
                  :clearable="true"
                  multiple
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchPermissions(res);
                    }
                  "
                  :loading="permissionsLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite a permissão</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              v-if="list && list.itens && list.itens.length > 0"
            >
              <h6>Variáveis disponíveis</h6>
              <b-overlay
                :variant="skin"
                :show="isloading"
                spinner-variant="primary"
                spinner-type="grow"
              >
                <b-table
                  :items="!isloading ? list.itens : []"
                  @row-clicked="onClickSelected"
                  :fields="fields"
                  :busy="isloading"
                  responsive
                  hover
                  bordered
                >
                  <template #cell(color)="data">
                    <b-badge pill :style="`background: ${data.value}`">
                      {{ data.value }}
                    </b-badge>
                  </template>
                  <template #cell(tag)="data">
                    <div class="d-flex align-items-center">
                      <span class="align-middle mr-75">{{
                        data.item.tag
                      }}</span>
                      <feather-icon
                        icon="CopyIcon"
                        size="18"
                        class="mr-75 text-success"
                      />
                    </div>
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </viewcard--c>
</template>
<script>
import _notificationService from "@/services/notification-template-service";
import _destinySendService from "@/services/destiny-send-service";
import _groupPermissionService from "@/services/group-permissions";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      btedit: { permission: `permission.notification.edit` },
      btcreate: { permission: `permission.notification.create` },
      btdelete: { permission: `permission.notification.delete` },
      loading: false,
      isloading: false,
      record: {
        id: 0,
        name: "",
        content: "",
        type_template: 0,
        is_system: false,
      },
      fields: [
        { key: "tag", label: "Tag" },
        { key: "title", label: "Título" },
      ],
      list: {
        itens: [],
      },
      destinies: [],
      destiniesSelected: [],
      permissions: [],
      permissionsSelected: [],
      permissionsLoading: false,
      types: [],
      typeSelected: { value: "0", label: "Projeto" },
    };
  },
  created() {
    this.getTypes();
    this.getDestinies();
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _notificationService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.destiniesSelected = this.record.notificationDestinies.map(
              (m) => {
                return {
                  label: m.destinySend.name,
                  value: m.destinySend.id,
                };
              }
            );

            this.permissionsSelected = this.record.notificationGroups.map(
              (m) => {
                return {
                  label: m.permission,
                  value: m.permission,
                };
              }
            );

            if (this.record.parameters) {
              try {
                let parameters = JSON.parse(this.record.parameters);
                parameters = parameters.slice().sort(function (a, b) {
                  return a.variable > b.variable ? 1 : -1;
                });
                parameters.forEach((element) => {
                  let params = {
                    tag: `{{${element.variable}}}`,
                    title: element.description,
                  };
                  this.list.itens.push(params);
                });
              } catch (ex) {}
            }

            if (this.record.type_template) {
              const type_template = this.types.filter(
                (f) => f.value === this.record.type_template
              );
              if (type_template && type_template.length > 0) {
                this.typeSelected = type_template[0];
              }
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    searchPermissions(_search) {
      if (_search && _search.length >= 3) {
        if (!this.permissionsLoading) {
          this.permissionsLoading = true;
          setTimeout(() => {
            _groupPermissionService
              .autoComplete(_search)
              .then((res) => {
                if (res && res.length > 0) {
                  this.permissions = [];
                  res.forEach((element) => {
                    this.permissions.push({
                      value: element.value,
                      label: element.value,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.permissionsLoading = false));
          }, 1500);
        }
      }
    },
    save() {
      this.record.type_template = this.typeSelected.value;
      if (this.permissionsSelected && this.permissionsSelected.length > 0) {
        this.record.notificationGroups = this.permissionsSelected.map((m) => {
          return {
            permission: m.value,
          };
        });
      }

      if (this.destiniesSelected && this.destiniesSelected.length > 0) {
        this.record.notificationDestinies = this.destiniesSelected.map((m) => {
          return {
            destinysend_id: m.value,
          };
        });
      }

      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _notificationService.create(payload)
          : _notificationService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Template salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.loading = true;
      _notificationService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Template excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    onClickSelected(record, _) {
      const el = document.createElement("textarea");
      el.value = record.tag;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    getTypes() {
      _notificationService
        .showTypes()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getDestinies() {
      _destinySendService
        .show()
        .then((res) => {
          this.destinies = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>